<template>

    <div style="display: grid; justify-content: center; width:100%; grid-template-columns: repeat(auto-fill, 28em); grid-gap: 1em;">
      <div class="elevation-3" v-for="(int, index) in integrations" :key="'int_' + index">

        <div style="padding: 1em; background-color: #fff;">
      
          <div :style="{width: '100%', height: '10em', 'background-image': 'url(' + require('@/assets/' + int.img) + ')', 'background-size': 'contain', 'background-position': 'center center'}">
          </div>

        </div>

          <div class="pa-3" style="width: 100% height: 5em; display:flex; justify-content: space-between; ">
            <div>
              <div class="display-2">{{int.title}}</div>
              <div class="text--disabled">{{int.subtitle}}</div>
            </div>

            <v-btn :disabled="int.disabled" class="mr-0" @click="int.handler" color="primary">{{int.btnLabel}}</v-btn>
          </div>

      </div>
    </div>

</template>


<script>
import lightspeedService from '@/services/lightspeed'
export default {

  computed: {
    integrations() {
      return [
        { 
          img: 'lightspeed.png',
          title: 'Lightspeed', 
          subtitle: this.$t('labels.ecr'), 
          disabled: (!this.$store.getters.brands || this.$store.getters.brands.length == 0),
          handler: () => { lightspeedService.start() }, 
          btnLabel: this.$t('labels.activate')
        },
        { 
          img: 'woocommerce.png',
          title: 'Woocommerce', 
          subtitle: this.$t('labels.webshop'), 
          handler: () => { window.location.href = "https://cashfreeids.s3-eu-west-1.amazonaws.com/libraries/bonsai-for-woocommerce.zip"; }, 
          btnLabel: this.$t('labels.download')
        },
        { 
          img: 'touchsystems.png',
          title: 'Touch Systems', 
          subtitle: this.$t('labels.ecr'), 
          handler: () => { window.location.href = "mailto:support@paybonsai.com"; }, 
          btnLabel: this.$t('labels.contact')
        },
        { 
          img: 'qrmenuapp.png',
          title: 'QR Menu App', 
          subtitle: this.$t('labels.qrMenu'), 
          handler: () => { window.location.href = "mailto:support@paybonsai.com"; }, 
          btnLabel: this.$t('labels.contact')
        },
        { 
          img: 'cloudpos.png',
          title: 'Cloud POS', 
          subtitle: this.$t('labels.ecr'), 
          handler: () => { window.location.href = "mailto:support@paybonsai.com"; }, 
          btnLabel: this.$t('labels.contact')
        }
      ]
    }
  }

}
</script>