import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import * as environment from './../environment.json'

const code_verifier_length = 50
const code_verifier_localstorage_key = "lightspeed_code_verifier"
const lightspeedauthurl = "https://lightspeedapis.com/resto/oauth2/v1/authorize"

function start() {
    const client_id = environment.VUE_APP_LIGHTSPEED_CLIENTID
    const code_verifier = generateCodeVerifier();
    const code_challenge = generateCodeChallenge(code_verifier);
    //window.localStorage.setItem(code_verifier_localstorage_key, code_verifier); // attention with doing this: some browser redirect to www. subdomain, other don't. Fix this!
    // For now we use a fixed one -_-

    var queryString = "?response_type=code"
        + "&client_id=" + client_id
        + "&code_challenge=" + code_challenge
        + "&code_challenge_method=S256";
    var url = lightspeedauthurl + queryString;

    // Lightspeed returns our user to https://paybonsai.com/lightspeed-integration-finish
    // To support multiple environments we set a cookie to redirect the user to the correct environment
    // That page can then redirect to the correct environment :)
    var cookieName = 'lightspeed-redirect';
    var cookieValue = environment.VUE_APP_LIGHTSPEED_REDIRECT;
    document.cookie = cookieName + "=" + cookieValue + ";domain=.paybonsai.com;path=/";
    window.setTimeout(() => {
        window.location = url // sometimes cookie isn't set in time?
    })

}

function generateCodeVerifier() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    for (var i = 0; i < code_verifier_length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return "_3s5HeyDNhPx8C9MYOEFWAgj9q9Iavvrxt-WtGGmrgIVB";
}
function generateCodeChallenge(code_verifier) {
    return base64URL(sha256(code_verifier))
}
function base64URL(data) {
    return Base64.stringify(data).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export default {
    start
}